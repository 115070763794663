<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addMerchant'})">
    {{ $t('discounts.addDiscount') }}
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div>
          <b-card class="iq-border-radius-10 mb-3">
            <template v-slot:header>
              <h5 class="text-primary"><i class="las la-file-alt"></i>
              {{ $t('main.discountInfo') }}
              </h5>
            </template>
            <b-row>
              <b-col md="6" class="mb-3">
                <input-form :placeholder="$t('main.enterName')" :label="$t('main.name')" v-model="offer.title" name="Name" validate="required"></input-form>
              </b-col>
              <b-col md="6" class="mb-3">
                <input-form :placeholder="$t('main.code')" :label="$t('main.code')" v-model="offer.code" name="code" validate="required"></input-form>
              </b-col>
              <!-- <b-col md="12" class="mb-3">
                <main-select :options="['all-orders']" label-title= 'استهداف' dir="rtl" v-model="offer.target"/>
              </b-col> -->
              <b-col md="6" class="mb-3">
                <input-form type="number" :placeholder="$t('discounts.numberOfUses')" :label="$t('discounts.numberOfUses')" v-model.number="offer.max_used" name="max_used" validate="required|numeric"></input-form>
              </b-col>
              <b-col md="6" class="mb-3">
                <input-form type="number" :placeholder="$t('discounts.numberOfUsesForUser')" :label="$t('discounts.numberOfUsesForUser')" v-model.number="offer.max_user_used" name="max_used" validate="required|numeric"></input-form>
              </b-col>
              <!-- <b-col md="6" class="mb-3">
                <main-select :options="['amount', 'percentage']" label-title= 'نوع العرض' dir="rtl" v-model="offer.type_option"/>
              </b-col> -->
              <b-col md="6" class="mb-3">
                <input-form type="number" :placeholder="$t('discounts.orderValue')" :label="$t('discounts.orderValue')" v-model.number="offer.value" name="value" validate="required|numeric"></input-form>
              </b-col>
              <!-- <b-col md="6" class="mb-3">
                <input-form placeholder="الحد الأقصى لقيمة الخصم" label="الحد الأقصى لقيمة الخصم" v-model="offer.max_discount" name="max_discount" validate="required|numeric"></input-form>
              </b-col> -->
              <b-col md="6" class="mb-3">
                <input-form type="number" :placeholder="$t('discounts.minOrderDiscount')" :label="$t('discounts.minOrderDiscount')" v-model.number="offer.min_order_price" name="min_order_price" validate="required|numeric"></input-form>
              </b-col>
              <b-col md="6" class="mb-3">
                <b-form-group
                    :label="$t('discounts.startDate')"
                    :label-for="$t('discounts.startDate')"
                    class="position-relative"
                    :label-class="'is_required'"
                >
                  <validation-provider
                      :name="$t('discounts.startDate')"
                      rules="required"
                      :bails="false"
                      #default="{errors}"
                  >
                    <i class="las la-calendar position-absolute text-gray font-size-20 datePickerIcon"></i>
                    <flat-pickr v-model="offer.start_at" :config="config" :class="['form-control bg-white date-error' , { 'is-invalid': errors.length > 0 }]"></flat-pickr>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" class="mb-3">
                <b-form-group
                    :label="$t('discounts.endDate')"
                    :label-for="$t('discounts.endDate')"
                    class="position-relative"
                    :label-class="'is_required'"
                >
                  <validation-provider
                      :name="$t('discounts.endDate')"
                      rules="required"
                      :bails="false"
                      #default="{errors}"
                  >
                    <i class="las la-calendar position-absolute text-gray font-size-20 datePickerIcon"></i>
                    <flat-pickr v-model="offer.end_at" :config="config" :class="['form-control bg-white date-error' , { 'is-invalid': errors.length > 0 }]"
                    ></flat-pickr>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="mb-3">
                <b-form-checkbox class="custom-radio-color" inline v-model="offer.can_use_with_promotion" :color="'primary'" name="color">
                  {{ $t('discounts.canBeUsedWithOffers')  }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-card>
          <!-- <b-card class="iq-border-radius-10 mb-3">
            <template v-slot:header>
              <h5 class="text-primary"><i class="las la-boxes"></i> المنتجات</h5>
            </template>
            <b-row>
              <b-col md="12" class="mb-3">
                <main-select label-title= 'المنتجات' multiple :options="allProducts" dir="rtl"
                              v-model="offer.products" label="name"
                              :selectable="option => !offer.products.includes(option)"
                              :reduce="product => product"
                />
              </b-col>
            </b-row>
          </b-card> -->
        </div>
        <div class="form-actions">
          <b-button variant="outline-primary" @click="$router.back()">
            {{ $t('main.back') }}
          </b-button>
          <b-button v-if="$route.name !== 'showProduct'" variant="primary" type="submit" :disabled="loadingSubmit">
            <span v-if="!loadingSubmit">
                {{ $t('main.save') }}
            </span>
            <spinner-loading v-else text="loading"></spinner-loading>
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import productServices from '@/modules/products/services/products'
import discountServices from '../services/discounts'
export default {
  components: { },
  mounted () {
    core.index()
  },
  data () {
    return {
      offer: {
        title: '',
        code: '',
        max_used: null,
        max_user_used: null,
        value: null,
        max_discount: null,
        can_use_with_promotion: null,
        type_option: 'amount',
        // target: 'all-orders',
        min_order_price: null,
        start_at: new Date(),
        end_at: null,
        products: [],
        product_collections: []
      },
      loadingSubmit: false,
      config: {
        altInput: true,
        dateFormat: 'Y-m-d',
        enableTime: true,
        minDate: this.start_at
      },
      allProducts: [],
      selectedProduct: []
    }
  },
  methods: {
    getAllMerchantProducts () {
      productServices.getAllMerchantProducts().then(res => {
        this.allProducts = res.data.data
      })
    },
    onSubmit () {
      discountServices.addNewDiscount(this.offer).then(res => {
        core.showSnackbar(res.data.message)
        this.$router.push({ name: 'discount' })
      })
      console.log('hello')
    }
  },
  created () {
    this.getAllMerchantProducts()
  }
}
</script>
0
